"use client";

import React, {
  useContext,
  ReactNode,
  useCallback,
  useMemo,
  useRef,
  useState,
  useEffect,
} from "react";
import parsePhoneNumberFromString from "libphonenumber-js";
import { message } from "antd";
import { useTranslations } from "next-intl";
import useCheckCallConditions from "@/providers/app/PhoneFeatures/useCheckCallConditions";
import PhoneFeaturesIframe from "@/providers/app/PhoneFeatures/PhoneFeaturesIframe";
import { useInterval } from "usehooks-ts";
import useMyAgent from "@/services/agent/useMyAgent";
import { useEnvironment } from "@/providers/app/EnvironmentProvider";
import { ILineCall } from "@/providers/app/PhoneFeatures/types";
import MockLine from "./PhoneFeatures/MockLine.json";
import MockInboundLine from "./PhoneFeatures/MockInboundLine.json";
import { useFloatingWindow } from "@/providers/app/FloatingWindowProvider";
import useDisclosure from "@/hooks/shared/useDisclosure";
import useNativeAppBridge from "@/bridge/useNativeAppBridge";
import { omit, pick } from "lodash";
import { useGlobalAppState } from "./GlobalAppStateProvider";
const stringify = require("fast-json-stable-stringify");
const MOCK_ENABLED = false;

interface PhoneFeaturesContextType {
  activeLineNumber?: number;
  setActiveLineNumber: (LineNumber: number) => void;
  callNumber: (phoneNumber: string, extraHeaders?: string[]) => void;
  enabled: boolean;
  enabledIframe: boolean;
  Lines: ILineCall[];
  registerStatus: string;
  readyToReceiveCall: boolean;
  toggleReadyToReceiveCall: () => void;
  toggleAutoAnswer: () => void;
  toggleCallWaiting: () => void;
  checklist: {
    register: {
      original_status: string;
      mapped_status: string;
      color: string;
      ready: boolean;
    };
    microphone: {
      ready: boolean;
    };
  };
  phoneContextRef: React.MutableRefObject<any>;
  collapsed: boolean;
  toggleCollapse: () => void;
  windowHeight: number;
  phoneOptions: {
    callWaiting: boolean;
    autoAnswer: boolean;
    doNotDisturb: boolean;
  };
}

const PhoneFeaturesContext = React.createContext<PhoneFeaturesContextType>({
  activeLineNumber: undefined,
  setActiveLineNumber: () => {},
  callNumber: () => {},
  enabled: false,
  enabledIframe: false,
  registerStatus: "waiting",
  readyToReceiveCall: false,
  toggleReadyToReceiveCall: () => {},
  toggleCallWaiting: () => {},
  toggleAutoAnswer: () => {},
  checklist: {
    register: {
      original_status: "waiting",
      mapped_status: "waiting",
      color: "yellow",
      ready: false,
    },
    microphone: {
      ready: false,
    },
  },
  Lines: [],
  phoneContextRef: undefined,
  collapsed: false,
  toggleCollapse: () => {},
  windowHeight: 520,
  phoneOptions: {
    callWaiting: false,
    autoAnswer: false,
    doNotDisturb: false,
  },
});

interface PhoneFeaturesProviderProps {
  children: ReactNode;
  usePopupWindow?: boolean;
}

export const PhoneFeaturesProvider: React.FC<PhoneFeaturesProviderProps> = ({
  children,
  usePopupWindow = true,
}) => {
  const { IS_MOBILE_APP_WEBVIEW } = useGlobalAppState();
  const { phoneKitSyncLines } = useNativeAppBridge();
  const { isOpen: collapsed, toggle: toggleCollapse } = useDisclosure();
  const [activeLineNumber, setActiveLineNumber] = React.useState(undefined);
  const { isOpen, openPhoneWindow, setDimensions } = useFloatingWindow();
  const { PHONE_IFRAME_SYNC_SPEED } = useEnvironment();
  const phoneContextRef = useRef();
  const [phoneOptions, setPhoneOptions] = useState({
    callWaiting: false,
    autoAnswer: false,
    doNotDisturb: false,
  });

  const readyToReceiveCall = !phoneOptions.doNotDisturb;

  const [Lines, setLines] = useState<ILineCall[]>(
    MOCK_ENABLED ? [MockInboundLine?.[0], MockLine?.[0]] : []
  );
  const t = useTranslations("phone_features");
  const { checkConditions, enabled, checklist } =
    useCheckCallConditions(phoneContextRef);
  const { data: myAgent, isRefetching: isLoadingMyAgent } = useMyAgent();

  // console.log(
  //   stringify(Lines, {
  //     cycles: true,
  //   })
  // );
  const callNumber = useCallback(
    (phoneNumber: string, extraHeaders?: string[]) => {
      checkConditions();
      const parsedNumber = parsePhoneNumberFromString(phoneNumber, "VN");
      if (!parsedNumber) {
        message.error(t("error.invalid_phone_number"));
        return;
      }
      const formattedNumber = parsedNumber
        .formatNational()
        ?.replaceAll(" ", "");

      phoneContextRef?.current?.DialByLine(
        "audio",
        null,
        formattedNumber,
        null,
        extraHeaders
      );
    },
    [t, checkConditions]
  );

  const enabledIframe = useMemo(() => {
    if (
      typeof window !== "undefined" &&
      IS_MOBILE_APP_WEBVIEW &&
      !window.location.href.includes("ucall-phone-kit")
    ) {
      return false;
    }

    return (
      (!!myAgent?.data?.ext_username && !!myAgent?.data?.ext_password) || false
    );
  }, [
    IS_MOBILE_APP_WEBVIEW,
    myAgent?.data?.ext_password,
    myAgent?.data?.ext_username,
  ]);

  const toggleReadyToReceiveCall = useCallback(() => {
    phoneContextRef?.current?.ToggleDoNoDisturb();
  }, []);

  const toggleCallWaiting = useCallback(() => {
    phoneContextRef?.current?.ToggleCallWaiting();
  }, []);

  const toggleAutoAnswer = useCallback(() => {
    phoneContextRef?.current?.ToggleAutoAnswer();
  }, []);

  useInterval(
    () => {
      try {
        const iframe = document.getElementById("phone-features-iframe");
        const iframeWindow = iframe?.contentWindow;
        if (iframeWindow) {
          phoneContextRef.current = iframeWindow;
          const readyToReceiveCallState =
            iframeWindow.DoNotDisturbEnabled === false;
          setPhoneOptions({
            callWaiting: iframeWindow.CallWaitingEnabled,
            autoAnswer: iframeWindow.AutoAnswerEnabled,
            doNotDisturb: iframeWindow.DoNotDisturbEnabled,
          });
          if (!MOCK_ENABLED && readyToReceiveCallState) {
            const _lines = [...iframeWindow.Lines]?.reverse();
            setLines(_lines);
            if (iframeWindow.Lines?.length === 1) {
              setActiveLineNumber(_lines?.[0]?.LineNumber);
            }
            if (iframeWindow.Lines?.length > 1) {
              setActiveLineNumber(iframeWindow.selectedLine);
            }
            if (iframeWindow.Lines?.length === 0) {
              setActiveLineNumber(undefined);
            }
          }
        }
      } catch (e) {
        console.log("Can not sync iframe", e);
      }
    },
    enabledIframe ? PHONE_IFRAME_SYNC_SPEED : null
  );
  const wrappedSetActiveLine = useCallback(
    (LineNumber: string) => {
      phoneContextRef?.current?.SwitchLines(LineNumber);
    },
    [phoneContextRef]
  );

  const windowHeight = useMemo(() => {
    if (collapsed) {
      return 240 + Lines.length * 120;
    }
    return 520;
  }, [collapsed, Lines]);

  useEffect(() => {
    setDimensions((prevDimensions) => ({
      ...prevDimensions,
      height: windowHeight,
    }));
  }, [windowHeight]);

  useEffect(() => {
    if (Lines.length > 0 && !isOpen && usePopupWindow) {
      openPhoneWindow();
    }
  }, [Lines]);

  useEffect(() => {
    if (Lines && Lines?.length === 1) {
      setActiveLineNumber(Lines[0]?.LineNumber);
    }
  }, [Lines]);

  useEffect(() => {
    if (IS_MOBILE_APP_WEBVIEW) return;
    // This object is really heavy and need to trim down before sent to native bridge
    const compressedLines = Lines.map((item) => ({
      ...pick(item, [
        "BuddyObj",
        "DisplayName",
        "DisplayNumber",
        "LineNumber",
        "IsSelected",
      ]),
      SipSession: {
        ...pick(item.SipSession, [
          "_id",
          "_state",
          "data",
          "isOnHold",
          "IsSelected",
        ]),
      },
    }));
    phoneKitSyncLines(
      stringify(
        {
          lines: compressedLines,
          phoneOptions,
          checklist,
        },
        {
          cycles: true,
        }
      )
    );
  }, [Lines, checklist, phoneKitSyncLines, phoneOptions]);

  return (
    <PhoneFeaturesContext.Provider
      value={{
        callNumber,
        readyToReceiveCall,
        toggleReadyToReceiveCall,
        toggleCallWaiting,
        toggleAutoAnswer,
        enabled,
        enabledIframe,
        checklist,
        Lines,
        phoneContextRef,
        activeLineNumber: activeLineNumber,
        setActiveLineNumber: wrappedSetActiveLine,
        collapsed,
        toggleCollapse,
        windowHeight,
        phoneOptions,
      }}>
      {children}
    </PhoneFeaturesContext.Provider>
  );
};

// Custom hook to use the context
export const usePhoneFeatures = () => useContext(PhoneFeaturesContext);
